import { createStore } from '@halka/state';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useState } from 'react';
import {
  pick,
  entries,
  get,
  initial,
  keys,
  last,
  omit,
  toPath,
  values,
  isEmpty,
  sortBy,
  indexOf,
  trimStart,
  trimEnd,
  keyBy,
  trim,
} from 'lodash-es';
import sortArray from 'sort-array';
import constate from 'constate';

import { translateVariablesToDateTimeString, typeBasedFilterOptions } from '../odata/queryBuilder';
import { ENUMS, flattenEntityType, formQueryURL } from '../odata/utils';
import { constructURL, extractPropertyName, wrapWithImmer } from '../utils';
import {
  getSortedProperties,
  OPERATORS,
  PAGINATION,
  SELECTED_OPTIONS_CHANGE_REASONS,
  useShareableUrl,
  WHERE_CONDITION,
} from './queryBuilder';
import { MULTIPLICITY } from '../components/ReportViewComponents/ExpandOption';
import {
  FETCH_CANCELLATION,
  ROOT_LEVEL_GROUP_NAME,
} from 'components/ReportViewComponents/constants';
import { parseQueryUrlAndPopulateQueryBuilder } from 'odata/queryStringParser';
import { createMinimalQuery } from 'odata/reportView';
import { cancelAllPendingBatchedRequests } from 'reportView/main';

function useQueryUrlStore({ baseEndpoint, baseParams }) {
  const baseUrl = formQueryURL(baseEndpoint, baseParams);

  const [queryUrl, setQueryUrl] = useState(baseUrl);
  const [translatedQueryUrl, setTranslatedQueryUrl] = useState(baseUrl);

  const updateQueryUrl = useCallback(
    (queryString) => {
      const url = formQueryURL(baseEndpoint, baseParams, queryString ?? '');
      const urlObj = new URL(url);
      setQueryUrl(urlObj.href);

      const translatedUrl = translateVariablesToDateTimeString(url);
      setTranslatedQueryUrl(translatedUrl);
    },
    [baseEndpoint, baseParams]
  );

  return { updateQueryUrl, queryUrl, translatedQueryUrl };
}

export const [QueryUrlProvider, useQueryUrl, useUpdateQueryUrl, useTranslatedQueryUrl] = constate(
  useQueryUrlStore,
  (context) => context.queryUrl,
  (context) => context.updateQueryUrl,
  (context) => context.translatedQueryUrl
);

const initialReportViewState = {
  current: {
    queryKey: nanoid(),

    entity: {
      options: [],
      value: null,
      optionsMap: {},
    },

    effectiveRange: {
      asOfDate: null,
      fromDate: null,
      toDate: null,
      balanceAsof: null,
    },

    where: {
      options: [],
      value: {
        id: nanoid(),
        operator: OPERATORS.AND,
        rules: [],
      },
    },

    sortBy: {
      options: [],
      value: [],
      flags: {},
    },

    visibleFields: {
      options: [],
      value: [],
      optionsMap: {},
    },

    expand: {
      options: [],
      value: [],
      optionsMap: {},
    },

    top: PAGINATION.TOP.INTERNAL_DEFAULT,
    skip: PAGINATION.SKIP.DEFAULT,
    isCustomPagination: false,
  },
  fork: {
    queryKey: nanoid(),

    entity: {
      options: [],
      value: null,
      optionsMap: {},
    },

    effectiveRange: {
      asOfDate: null,
      fromDate: null,
      toDate: null,
      balanceAsOf: null,
    },

    where: {
      options: [],
      value: {
        id: nanoid(),
        operator: OPERATORS.AND,
        rules: [],
      },
    },

    sortBy: {
      options: [],
      value: [],
      flags: {},
    },

    visibleFields: {
      options: [],
      value: [],
      optionsMap: {},
    },

    expand: {
      options: [],
      value: [],
      optionsMap: {},
    },

    top: PAGINATION.TOP.INTERNAL_DEFAULT,
    skip: PAGINATION.SKIP.DEFAULT,
    isCustomPagination: false,
  },
};

export const useReportViewState = createStore(initialReportViewState);
export const updateReportViewState = wrapWithImmer(useReportViewState.set);

export const useInitializeReportViewState = (schema, baseEndpoint, systemType) => {
  // For the initialization of Report View there are two scenarios that are possible:
  // 1. Report View is initialized with no data - blank slate
  // 2. Report View is initialized with the previous state.current state

  const { getQueryUrlFromLocation, purgeQueryStringFromLocation } = useShareableUrl();

  useEffect(() => {
    // If schema is not available, we exit the callback function immediately
    if (!schema) {
      return;
    }

    let odataQuery = getQueryUrlFromLocation();

    setTimeout(() => {
      if (odataQuery) {
        hydrateReportViewState(odataQuery, baseEndpoint, schema, systemType);
        purgeQueryStringFromLocation();
        return;
      }
    });

    updateReportViewState((state) => {
      // Check if there is a entity already selected in state.current
      const selectedEntity = state.current.entity.value;
      // If the state.current already has an entity selected, we load it
      // on state.fork for the user to execute.
      if (selectedEntity) {
        state.fork = state.current;
        state.current = initialReportViewState.current;
        return;
      }

      // If the state.current does not have an entity selected, we set the
      // state to the initialState and set the state.fork entity options based
      // on the schema passed to the hook
      const allEntities = schema.entityTypes.map(flattenEntityType);
      state.fork.entity.value = initialReportViewState.fork.entity.value;
      state.fork.entity.options = allEntities;
      state.fork.entity.optionsMap = keyBy(allEntities, 'name');

      state.fork.effectiveRange.asOfDate = initialReportViewState.fork.effectiveRange.asOfDate;
      state.fork.effectiveRange.fromDate = initialReportViewState.fork.effectiveRange.fromDate;
      state.fork.effectiveRange.toDate = initialReportViewState.fork.effectiveRange.toDate;
      state.fork.effectiveRange.balanceAsOf =
        initialReportViewState.fork.effectiveRange.balanceAsOf;

      state.fork.where.value = initialReportViewState.fork.where.value;
      state.fork.where.options = initialReportViewState.fork.where.options;

      state.fork.sortBy.value = initialReportViewState.fork.sortBy.value;
      state.fork.sortBy.options = initialReportViewState.fork.sortBy.options;
      state.fork.sortBy.flags = initialReportViewState.fork.sortBy.flags;

      state.fork.visibleFields.options = initialReportViewState.fork.visibleFields.options;
      state.fork.visibleFields.value = initialReportViewState.fork.visibleFields.options;
      state.fork.visibleFields.optionsMap = initialReportViewState.fork.visibleFields.optionsMap;

      state.fork.expand.value = initialReportViewState.fork.expand.value;
      state.fork.expand.options = initialReportViewState.fork.expand.options;
      state.fork.expand.optionsMap = initialReportViewState.fork.expand.optionsMap;

      state.current = initialReportViewState.current;
    });

    return () => {
      updateReportViewState((state) => {
        state.current = state.fork;
        state.fork = initialReportViewState.fork;
      });
    };
  }, [baseEndpoint, getQueryUrlFromLocation, purgeQueryStringFromLocation, schema, systemType]);
};

export function useOnReportViewStateUpdate({ isSfSystem, schema }) {
  const { fork } = useReportViewState();
  const updateQueryUrl = useUpdateQueryUrl();

  useEffect(() => {
    if (schema && fork.entity.value) {
      const queryString = createMinimalQuery({
        isSfSystem,
        schema,
        ...pickQueryParamsFromReportViewState(fork),
      });

      updateQueryUrl(queryString);
    } else {
      updateQueryUrl();
    }
  }, [schema, isSfSystem, updateQueryUrl, fork]);
}

export function hydrateReportViewState(odataQuery, baseEndpoint, schema, systemType) {
  const _odataQuery = odataQuery.startsWith(baseEndpoint)
    ? odataQuery.replace(baseEndpoint, '')
    : odataQuery;

  const queryUrl = `${trimEnd(baseEndpoint, '/')}/${trimStart(_odataQuery, '/')}`;

  const urlObj = constructURL(queryUrl);
  if (!urlObj) {
    return;
  }
  const selectParam = urlObj.searchParams.get('$select');
  const columnsFromURL = selectParam?.split(',');

  const stateSlice = parseQueryUrlAndPopulateQueryBuilder({
    queryUrl,
    baseEndpoint,
    schema,
    systemType,
  });

  const transformedState = {
    entity: stateSlice.entity,
    where: stateSlice.where.value,
    expand: { value: stateSlice.expand.value?.map(({ name }) => name) },
    visibleFields: { value: [] },
    effectiveRange: stateSlice.effectiveRange,
    orderBy: stateSlice.orderBy,
    top: stateSlice.top,
    skip: stateSlice.skip,
  };

  let selectedColumns = stateSlice.columnSelect.value;
  // If no columns were selected, we want to select the key and required columns by default
  if (selectedColumns.length === 0) {
    selectedColumns = stateSlice.columnSelect.options?.filter(
      ({ parent, isKey, required }) => !parent && (isKey || required)
    );
  }

  let columnsToBeHydrated = selectedColumns?.map(({ parent, name }) => {
    if (!parent) {
      return `${ROOT_LEVEL_GROUP_NAME}.${name}`;
    }

    if (name.includes('/')) {
      return name.replace('/', '.');
    }

    return name;
  });

  columnsToBeHydrated = sortBy(columnsToBeHydrated, (accessor) => {
    const [parent, columnName] = accessor.split('.');

    if (parent === ROOT_LEVEL_GROUP_NAME) {
      return indexOf(columnsFromURL, columnName);
    }

    return indexOf(columnsFromURL, `${parent}/${columnName}`);
  });

  transformedState.visibleFields.value = columnsToBeHydrated;

  const { entity, effectiveRange, where, expand, visibleFields, orderBy, top, skip } =
    transformedState;

  const isEntitySelected = !isEmpty(entity);

  updateReportViewState((state) => {
    const allEntities = schema.entityTypes.map(flattenEntityType);
    state.fork.entity.value = isEntitySelected ? entity : initialReportViewState.fork.entity.value;
    state.fork.entity.options = allEntities;
    state.fork.entity.optionsMap = keyBy(allEntities, 'name');

    state.fork.effectiveRange = effectiveRange;

    const allProperties = [...(entity?.property ?? initialReportViewState.fork.where.options)];
    getSortedProperties(allProperties);

    state.fork.where.value = where.rules.length ? where : initialReportViewState.fork.where.value;

    const expandedProperties = [];
    if (expand.value.length) {
      const expandOptionsMap = computeExpandOptionsMap(entity, schema);
      expand.value.forEach((navProp) => {
        const expandedColumns = computeExpandedColumns(schema, expandOptionsMap[navProp]);
        values(expandedColumns).forEach((columnData) => expandedProperties.push(columnData));
      });
    }

    state.fork.where.options = [...allProperties, ...expandedProperties];

    state.fork.sortBy.value = orderBy.value;
    state.fork.sortBy.options = orderBy.options
      ? computeOrderByOptions(entity, expandedProperties)
      : initialReportViewState.fork.sortBy.options;
    state.fork.sortBy.flags = orderBy.flags;

    state.fork.visibleFields.options = isEntitySelected
      ? computeVisibleFieldsOptions(entity, expandedProperties)
      : initialReportViewState.fork.visibleFields.options;
    state.fork.visibleFields.value = visibleFields.value.length
      ? visibleFields.value
      : initialReportViewState.fork.visibleFields.options;
    state.fork.visibleFields.optionsMap = isEntitySelected
      ? computeVisibleFieldsOptionsMap(entity, expandedProperties)
      : initialReportViewState.fork.visibleFields.optionsMap;

    state.fork.expand.value = expand.value.length
      ? expand.value
      : initialReportViewState.fork.expand.value;
    state.fork.expand.options = isEntitySelected
      ? computeExpandOptions(entity, schema)
      : initialReportViewState.fork.expand.options;
    state.fork.expand.optionsMap = isEntitySelected
      ? computeExpandOptionsMap(entity, schema)
      : initialReportViewState.fork.expand.optionsMap;

    if (top !== 0 || skip !== 0) {
      state.fork.isCustomPagination = true;
      state.fork.top = top ?? 0;
      state.fork.skip = skip ?? 0;
    }

    state.current = initialReportViewState.current;
  });
  cancelAllPendingBatchedRequests(FETCH_CANCELLATION.CANCEL_ALL_BATCHES);
}

export const reportViewStateHandlers = {
  // state handlers
  updateCurrentState: () => {
    updateReportViewState((state) => {
      state.current = state.fork;
    });
  },
  undoForkState: () => {
    updateReportViewState((state) => {
      state.fork = state.current;
    });
  },
  resetForkState: () => {
    updateReportViewState((state) => {
      const entityOptions = state.fork.entity.options;

      state.fork = initialReportViewState.fork;
      state.fork = {
        ...state.fork,
        queryKey: nanoid(),
        entity: {
          options: entityOptions,
          value: null,
        },
        top: 0,
        skip: 0,
        isCustomPagination: false,
      };
    });
  },
  resetAllState: () => {
    updateReportViewState((state) => {
      const entityOptions = state.fork.entity.options;

      state.current = initialReportViewState.current;
      state.fork = initialReportViewState.fork;

      state.fork = {
        ...state.fork,
        queryKey: nanoid(),
        entity: {
          options: entityOptions,
          value: null,
        },
      };
    });
  },

  // entity
  handleEntityChange: (schema) => (_, value) => {
    if (!value) {
      reportViewStateHandlers.resetForkState();
      return;
    }

    updateReportViewState((state) => {
      // If an entity is re-selected, we will exit from the function
      if (state.fork.entity.value === value) {
        return;
      }

      state.fork.queryKey = nanoid();

      const flattenedEntity = flattenEntityType(value, schema.complexTypesMap);

      // If flattenedEntity extraction fails, we must exit the function
      if (!flattenedEntity || isEmpty(flattenedEntity)) {
        return;
      }

      state.fork.entity.value = flattenedEntity;

      const allProperties = [...flattenedEntity?.property];
      getSortedProperties(allProperties);

      state.fork.effectiveRange.asOfDate = initialReportViewState.fork.effectiveRange.asOfDate;
      state.fork.effectiveRange.fromDate = initialReportViewState.fork.effectiveRange.fromDate;
      state.fork.effectiveRange.toDate = initialReportViewState.fork.effectiveRange.toDate;
      state.fork.effectiveRange.balanceAsOf =
        initialReportViewState.fork.effectiveRange.balanceAsOf;

      state.fork.where.options = allProperties;
      state.fork.where.value = initialReportViewState.fork.where.value;

      state.fork.sortBy.value = initialReportViewState.fork.sortBy.value;
      state.fork.sortBy.options = computeOrderByOptions(flattenedEntity);
      state.fork.sortBy.flags = initialReportViewState.fork.sortBy.flags;

      state.fork.visibleFields.value = computeVisibleFieldsValue(flattenedEntity);
      state.fork.visibleFields.options = computeVisibleFieldsOptions(flattenedEntity);
      state.fork.visibleFields.optionsMap = computeVisibleFieldsOptionsMap(flattenedEntity);

      state.fork.expand.value = initialReportViewState.fork.expand.value;
      state.fork.expand.options = computeExpandOptions(flattenedEntity, schema);
      state.fork.expand.optionsMap = computeExpandOptionsMap(flattenedEntity, schema);

      state.fork.top = initialReportViewState.fork.top;
      state.fork.skip = initialReportViewState.fork.skip;
      state.fork.isCustomPagination = false;
    });
  },

  // effectiveRange
  handleAsOfDateChange: (date) => {
    updateReportViewState((state) => {
      state.fork.effectiveRange.asOfDate = date;
      if (date) {
        state.fork.effectiveRange.fromDate = null;
        state.fork.effectiveRange.toDate = null;
      }
    });
  },
  handleFromDateChange: (date) => {
    updateReportViewState((state) => {
      state.fork.effectiveRange.fromDate = date;
    });
  },
  handleToDateChange: (date) => {
    updateReportViewState((state) => {
      state.fork.effectiveRange.toDate = date;
    });
  },
  handleBalanceAsOfChange: (date) => {
    updateReportViewState((state) => {
      state.fork.effectiveRange.balanceAsOf = date;
    });
  },

  // where
  handleAddNewRule: (path, type) => () => {
    updateReportViewState((state) => {
      const defaultProperty = state.fork.where.options[0];

      let rules;
      if (type === WHERE_CONDITION.TYPE.GROUP) {
        rules = get(state.fork, `${path}.rules`);
      } else {
        rules = get(state.fork, initial(toPath(path)));
      }

      const newRule = {
        id: nanoid(),
        property: defaultProperty,
        filter: typeBasedFilterOptions[defaultProperty.type][0],
        param: '',
      };

      rules.push(newRule);
    });
  },

  // top and skip
  handleUpdateTopValue: (value) => {
    updateReportViewState((state) => {
      state.fork.top = value;
      const skipValue = state.fork.skip;

      const isCustomPagination = !(value === 0 && skipValue === 0);

      state.fork.isCustomPagination = isCustomPagination;
    });
  },
  handleUpdateSkipValue: (value) => {
    updateReportViewState((state) => {
      state.fork.skip = value;
      const topValue = state.fork.top;

      const isCustomPagination = !(value === 0 && topValue === 0);

      state.fork.isCustomPagination = isCustomPagination;
    });
  },
  handleUpdateIsCustomPagination: (isCustom) => {
    updateReportViewState((state) => {
      state.fork.isCustomPagination = isCustom;
    });
  },

  handleUpdateRuleProperty: (path) => (_, value) => {
    updateReportViewState((state) => {
      const rule = get(state.fork, path);
      rule.property = value;
      rule.filter = typeBasedFilterOptions[value.type][0];
      rule.param = '';
    });
  },
  handleUpdateRuleFilter: (path) => (_, value) => {
    updateReportViewState((state) => {
      const rule = get(state.fork, path);
      rule.filter = value;
      if (rule.filter.group !== value.group) {
        rule.param = '';
      }
    });
  },
  handleUpdateRuleParam: (path) => (value) => {
    updateReportViewState((state) => {
      const rule = get(state.fork, path);
      if (typeof value !== 'string') {
        rule.param = value;
        return;
      }

      if (value?.includes(';')) {
        rule.param = value
          .split(';')
          .map((param) => param.trim())
          .join(';');
        return;
      }
      rule.param = trim(value);
      return;
    });
  },
  handleAddNewGroup: (path) => () => {
    updateReportViewState((state) => {
      const rules = get(state.fork, `${path}.rules`);

      rules.push({
        id: nanoid(),
        operator: OPERATORS.AND,
        rules: [],
      });
    });
  },
  handleToggleGroupOperator: (path) => () => {
    updateReportViewState((state) => {
      const group = get(state.fork, path);
      group.operator = group.operator === OPERATORS.AND ? OPERATORS.OR : OPERATORS.AND;
    });
  },
  handleDeleteRuleOrGroup: (path) => () => {
    updateReportViewState((state) => {
      const _path = toPath(path);
      const rules = get(state.fork, initial(_path));

      rules.splice(Number(last(_path)), 1);
    });
  },

  // sortBy
  handleChangeSort: (_, values, reason) => {
    updateReportViewState((state) => {
      state.fork.sortBy.value = values;

      if (reason === SELECTED_OPTIONS_CHANGE_REASONS.MUI5_ADD) {
        values.forEach((_value) => {
          if (!state.fork.sortBy.flags[_value.name]) {
            state.fork.sortBy.flags[_value.name] = ENUMS.ORDER_BY_FLAG.ASC;
          }
        });
      }

      if (reason === SELECTED_OPTIONS_CHANGE_REASONS.MUI5_REMOVE) {
        if (!values.length) {
          state.fork.sortBy.flags = {};
        }

        const unremovedSorts = values.map((val) => val.name);
        state.fork.sortBy.flags = pick(state.fork.sortBy.flags, unremovedSorts);
      }

      if (reason === SELECTED_OPTIONS_CHANGE_REASONS.MUI5_CLEAR) {
        state.fork.sortBy.flags = {};
      }
    });
  },
  handleToggleSortFlag: (columnName) => () => {
    updateReportViewState((state) => {
      state.fork.sortBy.flags[columnName] =
        state.fork.sortBy.flags[columnName] === ENUMS.ORDER_BY_FLAG.ASC
          ? ENUMS.ORDER_BY_FLAG.DESC
          : ENUMS.ORDER_BY_FLAG.ASC;
    });
  },
  handleRemoveSort: (index, propertyName) => () => {
    updateReportViewState((state) => {
      state.fork.sortBy.value.splice(index, 1);
      state.fork.sortBy.flags = omit(state.fork.sortBy.flags, propertyName);
    });
  },

  // visibleFields
  handleToggleFieldVisibility: (event, status) => {
    const propertyName = event.currentTarget.name;

    updateReportViewState((state) => {
      if (status) {
        const isPropertyAlreadyVisible = state.fork.visibleFields.value.includes(propertyName);
        if (!isPropertyAlreadyVisible) {
          // if the property is not present already, then we spread out the visibleFields value ->
          // push the new propertyName to the array -> sort the array (to make sure that the new
          // column is sorted properly) -> update the state
          const columnsNames = [...state.fork.visibleFields.value];
          columnsNames.push(propertyName);

          const columns = getSortedColumns(columnsNames, state.fork);

          state.fork.visibleFields.value = columns;
        }

        return;
      }

      // If the status is false aka the toggle is OFF, we filter the state.fork.visibleFields.value
      // and remove the property from the array
      state.fork.visibleFields.value = state.fork.visibleFields.value.filter(
        (fieldName) => fieldName !== propertyName
      );
    });
  },
  handleToggleAllFieldsVisibility: (event, status) => {
    const parent = event.currentTarget.name;

    updateReportViewState((state) => {
      if (status) {
        // Then we go through the optionsMaps of the selected parent to iterate through all the
        // properties inside that parent.
        keys(state.fork.visibleFields.optionsMap[parent]).forEach((propertyKey) => {
          const columnInfo = state.fork.visibleFields.optionsMap[parent][propertyKey];

          const propertyName = `${parent}.${propertyKey}`;
          const isPropertyAlreadyVisible = state.fork.visibleFields.value.includes(propertyName);
          if (!isPropertyAlreadyVisible && columnInfo.visible) {
            // if the toggle is ON, we need to make sure that all the properties that are not
            // visible should be visible. For that we first need the list of all the visible fields
            const columnsNames = [...state.fork.visibleFields.value];
            columnsNames.push(`${parent}.${propertyKey}`);

            const columns = getSortedColumns(columnsNames, state.fork);

            state.fork.visibleFields.value = columns;
          }
        });

        return;
      }

      const requiredProperties = entries(state.fork.visibleFields.optionsMap).reduce(
        (acc, [groupName, groupData]) => {
          values(groupData).forEach((property) => {
            // if the iterated group is same as the group which the user toggled off then
            // we want to make sure that we toggle all the fields off excepts for the key
            // and required fields.
            let fieldName;
            if (groupName === parent) {
              if (property.isKey) {
                fieldName = property.parent
                  ? property.name.replace('/', '.')
                  : `baseProperties.${property.accessor}`;
              }
            }
            // if the iteratted group is not the one that the user toggled off then we
            // want to make sure that all the fields of this group are visible
            else {
              fieldName = property.parent
                ? property.name.replace('/', '.')
                : `baseProperties.${property.accessor}`;
            }

            acc.push(fieldName);
          });

          return acc;
        },
        []
      );

      state.fork.visibleFields.value = state.fork.visibleFields.value.filter((fieldName) =>
        requiredProperties.includes(fieldName)
      );
    });
  },
  handlePinColumn: (parent, propertyName, status) => {
    updateReportViewState((state) => {
      const columnsNames = [...state.current.visibleFields.value];

      if (
        state.current.visibleFields.optionsMap[parent] &&
        state.current.visibleFields.optionsMap[parent][propertyName]
      ) {
        state.current.visibleFields.optionsMap[parent][propertyName].isPinned = status;

        const currentColumns = getSortedColumns(columnsNames, state.current);

        state.current.visibleFields.value = currentColumns;
      }

      if (
        state.fork.visibleFields.optionsMap[parent] &&
        state.fork.visibleFields.optionsMap[parent][propertyName]
      ) {
        state.fork.visibleFields.optionsMap[parent][propertyName].isPinned = status;

        const forkColumns = getSortedColumns(columnsNames, state.fork);

        state.fork.visibleFields.value = forkColumns;
      }
    });
  },

  // expand
  handleToggleNavExpansion: (schema) => (event, status) => {
    // We get the name of the nav prop expanded from event.currentTarget.name
    const navPropName = event.currentTarget.name;

    updateReportViewState((state) => {
      // Here we grab the details of the expanded prop from expand.optionsMap
      const selectedNavProp = state.fork.expand.optionsMap[navPropName];
      // Then we compute all the columns related to the expanded navigational property
      const expandedColumns = computeExpandedColumns(schema, selectedNavProp);

      if (status) {
        // if the navigational prop name has not been added to expand.value before, we proceed further.
        // This check is just for safety reasons.
        if (!state.fork.expand.value.includes(selectedNavProp.name)) {
          // The first thing we do is add the selected navigation property's name to expand.value
          state.fork.expand.value.push(selectedNavProp.name);

          const basePrimaryKeyFieldsToExpand = values(
            state.fork.visibleFields.optionsMap.baseProperties
          ).reduce((acc, propertyData) => {
            if (propertyData.isKey) {
              acc.push(`baseProperties.${propertyData.accessor}`);
            }
            return acc;
          }, []);

          basePrimaryKeyFieldsToExpand.forEach((field) => {
            if (!state.fork.visibleFields.value.includes(field)) {
              state.fork.visibleFields.value.splice(0, 0, field);
            }
          });

          const _expandedColumns = values(expandedColumns);
          sortArray(_expandedColumns, {
            by: ['isKey', 'required', 'label'],
            order: ['isKey', 'required', 'asc'],
            customOrders: {
              isKey: [true, false],
              required: [true, false],
            },
          });

          _expandedColumns.forEach((columnData) => {
            // Then we add the names of the column related to the expanded property to visibleFields.value and the complete column data to
            // visibleFields.options
            const fieldName = `${selectedNavProp.name}.${columnData.id}`;

            // When a nav-prop is expanded, we only want to show the required and isKey fields by default
            // the rest can be toggled on/off by the users
            if (columnData.isKey || columnData.required) {
              state.fork.visibleFields.value.push(fieldName);
            }
            state.fork.visibleFields.options.push(columnData);

            // We also push the expanded columns to where.options, so that they show up in the autocomplete options
            state.fork.where.options.push(columnData);

            // We do the same for sortBy aswell, all the columns that are sortable are added to sortBy.options
            columnData.sortable &&
              columnData.multiplicity !== MULTIPLICITY['*'].KEY &&
              state.fork.sortBy.options.push(columnData);
          });

          // Then finally we add the key-value pair of the columns (expandedNavPropName: {propertyName: propertyData}...) to visibleFields.optionsMap
          state.fork.visibleFields.optionsMap[selectedNavProp.name] = expandedColumns;
        }
      } else {
        // When an expand is deselected, we remove it from the expand.value state
        state.fork.expand.value = state.fork.expand.value.filter(
          (fieldName) => fieldName !== navPropName
        );

        // When an expand is deselected, we want to remove all the columns related to that expand from the visibleFields.options,
        // visibleFields.value and visibleFields.optionsMap
        state.fork.visibleFields.value = state.fork.visibleFields.value.filter(
          (fieldName) => !fieldName.startsWith(navPropName)
        );
        state.fork.visibleFields.options = state.fork.visibleFields.options.filter(
          (fieldName) => fieldName.parent !== navPropName
        );
        state.fork.visibleFields.optionsMap = omit(
          state.fork.visibleFields.optionsMap,
          selectedNavProp.name
        );

        // When an expand is deselected, we want to remove all the expanded columns from the where filter options
        state.fork.where.options = state.fork.where.options.filter(
          (fieldName) => fieldName.parent !== navPropName
        );

        // When an expand is deselected, we want all the expanded columns to be removed from the sortBy options
        state.fork.sortBy.options = state.fork.sortBy.options.filter(
          (fieldName) => fieldName.parent !== navPropName
        );
      }
    });
  },
  handleToggleAllOneMultiplicityExpands: (schema) => (event, status) => {
    updateReportViewState((state) => {
      if (status) {
        // If toggle all expands switch is enabled, add ALL one mulitiplicity
        // expands on top of already selected expands and remove duplicates
        const allOneMutliplicityExpands = getAllOneMultiplicityExpands(
          state.fork.expand.optionsMap
        );
        state.fork.expand.value = [
          ...new Set([...allOneMutliplicityExpands, ...state.fork.expand.value]),
        ];

        state.fork.expand.value.forEach((expand) => {
          const selectedNavProp = state.fork.expand.optionsMap[expand];
          const expandedColumns = computeExpandedColumns(schema, selectedNavProp);

          const basePrimaryKeyFieldsToExpand = values(
            state.fork.visibleFields.optionsMap.baseProperties
          ).reduce((acc, propertyData) => {
            if (propertyData.isKey) {
              acc.push(`baseProperties.${propertyData.accessor}`);
            }
            return acc;
          }, []);

          basePrimaryKeyFieldsToExpand.forEach((field) => {
            if (!state.fork.visibleFields.value.includes(field)) {
              state.fork.visibleFields.value.splice(0, 0, field);
            }
          });

          const _expandedColumns = values(expandedColumns);
          sortArray(_expandedColumns, {
            by: ['isKey', 'required', 'label'],
            order: ['isKey', 'required', 'asc'],
            customOrders: {
              isKey: [true, false],
              required: [true, false],
            },
          });

          _expandedColumns.forEach((columnData) => {
            // Then we add the names of the column related to the expanded property to visibleFields.value and the complete column data to
            // visibleFields.options
            const fieldName = `${selectedNavProp.name}.${columnData.id}`;

            // When a nav-prop is expanded, we only want to show the required and isKey fields by default
            // the rest can be toggled on/off by the users
            if (columnData.isKey || columnData.required) {
              state.fork.visibleFields.value.push(fieldName);
            }
            state.fork.visibleFields.options.push(columnData);

            // We also push the expanded columns to where.options, so that they show up in the autocomplete options
            state.fork.where.options.push(columnData);

            // We do the same for sortBy aswell, all the columns that are sortable are added to sortBy.options
            if (columnData.sortable && columnData.multiplicity !== MULTIPLICITY['*'].KEY) {
              state.fork.sortBy.options.push(columnData);
            }
          });

          // Then finally we add the key-value pair of the columns (expandedNavPropName: {propertyName: propertyData}...) to visibleFields.optionsMap
          state.fork.visibleFields.optionsMap[selectedNavProp.name] = expandedColumns;
        });
      } else {
        // If toggle all expands switch is disabled, remove ALL one multiplicity
        // expands from the curretly selected expands
        const currentSelectedExpands = state.fork.expand.value;
        const oneMultiplicityExpands = getAllOneMultiplicityExpands(state.fork.expand.optionsMap);

        const expandsListwithoutOneMultiplicityExpands = currentSelectedExpands.filter(
          (expand) => !oneMultiplicityExpands.includes(expand)
        );

        state.fork.expand.value = expandsListwithoutOneMultiplicityExpands;

        currentSelectedExpands.forEach((expand) => {
          // When an expand is deselected, we want to remove all the columns related to that expand from the visibleFields.options,
          // visibleFields.value and visibleFields.optionsMap
          state.fork.visibleFields.value = state.fork.visibleFields.value.filter(
            (fieldName) => !fieldName.startsWith(expand)
          );
          state.fork.visibleFields.options = state.fork.visibleFields.options.filter(
            (fieldName) => fieldName.parent !== expand
          );
          state.fork.visibleFields.optionsMap = omit(state.fork.visibleFields.optionsMap, expand);

          // When an expand is deselected, we want to remove all the expanded columns from the where filter options
          state.fork.where.options = state.fork.where.options.filter(
            (fieldName) => fieldName.parent !== expand
          );

          // When an expand is deselected, we want all the expanded columns to be removed from the sortBy options
          state.fork.sortBy.options = state.fork.sortBy.options.filter(
            (fieldName) => fieldName.parent !== expand
          );
        });
      }
    });
  },
};

function computeVisibleFieldsOptions(selectedEntity, expandedProperties = []) {
  const allProperties = [...selectedEntity?.property, ...expandedProperties];

  const allPropertiesSnapshot = [...allProperties];
  sortArray(allPropertiesSnapshot, {
    by: ['isKey', 'required', 'label'],
    order: ['isKey', 'required', 'asc'],
    customOrders: {
      isKey: [true, false],
      required: [true, false],
    },
  });

  return allPropertiesSnapshot.reduce((allColumns, property) => {
    const propertyName = extractPropertyName(property);

    const accessor = property.accessor ? property.accessor : propertyName;
    const nestingLevel = property.parent ? '1' : '0';
    const isPinned = false;

    const propertyData = { ...property, accessor, nestingLevel, isPinned };

    allColumns = [...allColumns, propertyData];

    return allColumns;
  }, []);
}

function computeVisibleFieldsValue(selectedEntity, expandedProperties = []) {
  const allProperties = computeVisibleFieldsOptions(selectedEntity, expandedProperties);

  return allProperties
    .filter((property) => (property.isKey || property.required) && property.visible)
    .map((property) => {
      const parent = property.parent ?? ROOT_LEVEL_GROUP_NAME;
      const propertyName = extractPropertyName(property);

      return `${parent}.${propertyName}`;
    });
}

function computeVisibleFieldsOptionsMap(selectedEntity, expandedProperties = []) {
  const allProperties = computeVisibleFieldsOptions(selectedEntity, expandedProperties);

  return allProperties.reduce((allColumns, property) => {
    const parentName = property.parent ?? ROOT_LEVEL_GROUP_NAME;
    const propertyName = extractPropertyName(property);

    allColumns[parentName] = { ...allColumns[parentName], [propertyName]: { ...property } };

    return allColumns;
  }, {});
}

export function computeExpandedColumns(schema, navProp) {
  // Only nested expands have parents and we don't want to show any nested
  // expands in the report view. So, if a navProp has a parent, we exit the function
  if (navProp.parent) {
    return {};
  }

  const association = schema.associationsMap[navProp.relationship];
  const toRole = association.end.find(({ role }) => role === navProp.toRole);

  const properties = flattenEntityType(
    schema.entityTypesMap[toRole.type],
    schema.complexTypesMap
  ).property;

  return properties.reduce((allExpandedProperties, property) => {
    if (property.visible) {
      allExpandedProperties[property.name] = {
        ...property,
        name: `${navProp.name}/${property.name}`,
        accessor:
          toRole.multiplicity === MULTIPLICITY['*'].KEY
            ? `${navProp.name}.index.${property.name}`
            : `${navProp.name}_${property.name}`,
        parent: navProp.name,
        nestingLevel: '1',
        isPinned: false,
        multiplicity: toRole.multiplicity,
        id: property.name,
      };
    }

    return allExpandedProperties;
  }, {});
}

function computeExpandOptions(selectedEntity, schema, parent) {
  return selectedEntity?.navigationProperty?.map((expandableProperty) => {
    const association = schema.associationsMap[expandableProperty.relationship];
    const toRole = association.end.find(({ role }) => role === expandableProperty.toRole);

    return {
      ...expandableProperty,
      association,
      multiplicity: toRole.multiplicity,
      parent: parent ?? undefined,
      name: `${parent ?? ''}${parent ? ENUMS.DELIMITERS.EXPAND : ''}${expandableProperty.name}`,
    };
  });
}

export function computeExpandOptionsMap(selectedEntity, schema, parent) {
  return selectedEntity?.navigationProperty?.reduce((allNavigationProperties, property) => {
    const association = schema.associationsMap[property.relationship];
    const toRole = association.end.find(({ role }) => role === property.toRole);

    allNavigationProperties[property.name] = {
      ...property,
      association,
      multiplicity: toRole.multiplicity,
      parent: parent ?? undefined,
      name: `${parent ?? ''}${parent ? ENUMS.DELIMITERS.EXPAND : ''}${property.name}`,
    };

    return allNavigationProperties;
  }, {});
}

export function computeOrderByOptions(selectedEntity, expandedProperties = []) {
  const allProperties = [...selectedEntity?.property, ...expandedProperties];
  const sortableProperties = allProperties.filter((prop) => prop.sortable);

  sortArray(sortableProperties, {
    by: ['isKey', 'required', 'label'],
    order: ['isKey', 'required', 'asc'],
    customOrders: {
      isKey: [true, false],
      required: [true, false],
    },
  });

  return sortableProperties;
}

const getSortedColumns = (columnsNames, state) => {
  const columns = columnsNames.map((column) => {
    return get(state.visibleFields.optionsMap, column);
  });

  sortArray(columns, {
    by: ['isPinned', 'nestingLevel', 'parent', 'isKey', 'required', 'label'],
    order: ['isPinned', 'nestingLevel', 'asc', 'isKey', 'required', 'asc'],
    customOrders: {
      isPinned: [true, false],
      nestingLevel: ['0', '1'],
      isKey: [true, false],
      required: [true, false],
    },
  });

  return columns.map((column) => {
    const parent = column.parent ?? ROOT_LEVEL_GROUP_NAME;
    const propertyName = extractPropertyName(column);

    return `${parent}.${propertyName}`;
  });
};

export const pickQueryParamsFromReportViewState = (state) => ({
  entity: state.entity.value,
  ...state.effectiveRange,
  where: state.where.value,
  sortBy: state.sortBy.value,
  sortByFlags: state.sortBy.flags,
  expand: state.expand.value,
  columnSelect: state.visibleFields.value,
  top: state.top,
  skip: state.skip,
});

export const getAllOneMultiplicityExpands = (optionsMap) => {
  const oneMultiplicityExpands = Object.values(optionsMap).reduce((acc, obj) => {
    if (obj.multiplicity === '0..1' || obj.multiplicity === '1') {
      acc.push(obj.name);
    }
    return acc;
  }, []);

  return oneMultiplicityExpands;
};
