import React from 'react';

export default function UnsupportedBrowser(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="783.574"
      height="464.726"
      data-name="Layer 1"
      viewBox="0 0 783.574 464.726"
      {...props}
    >
      <path
        fill="#ffb8b8"
        d="M568.316 223.42a9.377 9.377 0 0113.829 3.938l21.42-.565 3.519 12.933-30.261.374a9.428 9.428 0 01-8.507-16.68z"
      ></path>
      <path
        fill="#354a5f"
        d="M592.341 244.093a7.176 7.176 0 01-2.269-4.771l-.856-12.857a7.153 7.153 0 012.784-6.16l34.535-26.532 20.075-24.368a12.569 12.569 0 0117.06-2.175 12.55 12.55 0 012.67 17.679l-23.383 31.217-16.647 17.63a36.207 36.207 0 01-19.788 10.701l-8.032 1.443a7.172 7.172 0 01-5.68-1.407q-.244-.19-.469-.4z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M750.584 445.362L739.493 448.132 723.53 406.669 739.9 402.581 750.584 445.362z"
      ></path>
      <path
        fill="#2f2e41"
        d="M730.779 446.942l21.39-5.342 3.364 13.468-34.86 8.706a13.882 13.882 0 0110.106-16.832z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M650.479 453.158L639.047 453.157 633.611 409.061 650.483 409.062 650.479 453.158z"
      ></path>
      <path
        fill="#2f2e41"
        d="M630.881 449.89h22.048v13.882h-35.93a13.882 13.882 0 0113.882-13.882z"
      ></path>
      <circle cx="645.523" cy="110.318" r="24.561" fill="#ffb8b8"></circle>
      <path
        fill="#2f2e41"
        d="M645.476 111.188c.045-3.285 5.699-3.696 7.46-.896s.631 6.452-.92 9.368c-1.616 3.035-3.71 6.1-3.388 9.547a5.198 5.198 0 007.963 3.719c8.023-4.94 14.795-12.13 17.792-20.893 3.151-9.217 1.576-20.203-5.24-27.285s-19.036-9.084-27.126-3.423l-13.81-9.405a1.927 1.927 0 00-2.964 2.095l2.02 6.563-6.976-1.755a1.533 1.533 0 00-1.711 2.286l2.394 3.72c-3.193-2.627-8.71.368-9.168 4.42s2.836 7.787 6.709 9.313 8.197 1.353 12.369 1.155a9.823 9.823 0 004.557 10.575c3.608 2.091 8.59 1.598 10.039.896zM650.797 434.536c-.048 0-.095 0-.143-.002l-17.658-.55a4.5 4.5 0 01-4.353-4.743l8.832-162.167 67.86 19.244 44.498 138.783a4.5 4.5 0 01-4.165 5.873l-20.339.54a4.542 4.542 0 01-4.298-2.828l-45.77-114.494a1.5 1.5 0 00-2.877.338l-17.138 116.163a4.5 4.5 0 01-4.45 3.843z"
      ></path>
      <path
        fill="#354a5f"
        d="M702.041 307.76a22.88 22.88 0 01-13.418-4.361l-21.836-15.762a36.852 36.852 0 00-16.184-6.559l-10.506-1.532a6.565 6.565 0 01-5.627-6.788l4.187-100.25-2.485-14.29a6.181 6.181 0 012.593-6.146l19.461-13.315a8.142 8.142 0 015.23-1.395l.711.056a9.53 9.53 0 018.503 7.195l.746 2.985 12.948 23.018a85.423 85.423 0 018.548 21.681l12.274 50.44a90.979 90.979 0 012.21 13.347l4.064 45.36a4.55 4.55 0 01-2.859 4.658 23.028 23.028 0 01-8.56 1.657z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M661.309 271.432a9.377 9.377 0 019.012-11.204l7.82-19.95 13.282 1.795-11.438 28.018a9.428 9.428 0 01-18.676 1.34z"
      ></path>
      <path
        fill="#354a5f"
        d="M689.705 257.352a7.176 7.176 0 01-5.278.233l-12.175-4.217a7.153 7.153 0 01-4.59-4.964l-10.992-42.14-14.628-27.978a12.569 12.569 0 014.638-16.56 12.55 12.55 0 0117.324 4.423l19.65 33.693 9.756 22.197a36.207 36.207 0 012.153 22.393l-1.8 7.96a7.173 7.173 0 01-3.506 4.684q-.271.15-.552.276z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M559.995 340.901H17.655a16.519 16.519 0 01-16.5-16.5v-305.8h575.34v305.8a16.519 16.519 0 01-16.5 16.5z"
      ></path>
      <path
        fill="#fff"
        d="M538.412 309.318H39.237a8.51 8.51 0 01-8.5-8.5V58.684a8.51 8.51 0 018.5-8.5h499.175a8.51 8.51 0 018.5 8.5v242.134a8.51 8.51 0 01-8.5 8.5z"
      ></path>
      <path
        fill="#354a5f"
        d="M577.65 29.748H0V16.5A16.519 16.519 0 0116.5 0h544.65a16.519 16.519 0 0116.5 16.5z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M166.25 97.706H77.4a8.506 8.506 0 00-8.5 8.5v58.4a8.506 8.506 0 008.5 8.5h88.85a8.512 8.512 0 008.5-8.5v-58.4a8.512 8.512 0 00-8.5-8.5zM333.25 97.706H244.4a8.506 8.506 0 00-8.5 8.5v58.4a8.506 8.506 0 008.5 8.5h88.85a8.512 8.512 0 008.5-8.5v-58.4a8.512 8.512 0 00-8.5-8.5zM500.25 97.706H411.4a8.506 8.506 0 00-8.5 8.5v58.4a8.506 8.506 0 008.5 8.5h88.85a8.512 8.512 0 008.5-8.5v-58.4a8.512 8.512 0 00-8.5-8.5zM153.68 200.706H89.97a4.725 4.725 0 100 9.45h63.71a4.725 4.725 0 100-9.45zM153.68 226.526H89.97a4.722 4.722 0 00-1.94 9.03 4.662 4.662 0 001.94.42h63.71a4.725 4.725 0 100-9.45zM153.68 252.346H89.97a4.725 4.725 0 100 9.45h63.71a4.725 4.725 0 100-9.45zM320.68 200.706h-63.71a4.725 4.725 0 100 9.45h63.71a4.725 4.725 0 100-9.45zM320.68 226.526h-63.71a4.725 4.725 0 100 9.45h63.71a4.725 4.725 0 100-9.45zM320.68 252.346h-63.71a4.725 4.725 0 00-1.49 9.21 4.605 4.605 0 001.49.24h63.71a4.725 4.725 0 100-9.45zM487.68 200.706h-63.71a4.725 4.725 0 100 9.45h63.71a4.725 4.725 0 100-9.45zM487.68 226.526h-63.71a4.722 4.722 0 00-1.94 9.03 4.662 4.662 0 001.94.42h63.71a4.725 4.725 0 100-9.45zM487.68 252.346h-63.71a4.725 4.725 0 100 9.45h63.71a4.725 4.725 0 100-9.45z"
      ></path>
      <circle cx="18.925" cy="15.049" r="4.283" fill="#fff"></circle>
      <circle cx="35.184" cy="15.049" r="4.283" fill="#fff"></circle>
      <circle cx="51.443" cy="15.049" r="4.283" fill="#fff"></circle>
      <path
        fill="#354a5f"
        d="M135.55 200.706H89.97a4.725 4.725 0 100 9.45h44.63a5.38 5.38 0 00.95-9.45zM114.5 226.526H89.97a4.722 4.722 0 00-1.94 9.03h22.53a5.382 5.382 0 005.38-5.38 5.27 5.27 0 00-1.44-3.65zM148.92 252.346H89.97a4.725 4.725 0 100 9.45h59.33a5.368 5.368 0 00-.38-9.45zM439.66 200.706h-15.69a4.725 4.725 0 00-2.31 8.85h13.9a5.382 5.382 0 005.38-5.38 5.295 5.295 0 00-1.28-3.47zM451.5 226.526h-27.53a4.722 4.722 0 00-1.94 9.03h25.53a5.382 5.382 0 005.38-5.38 5.27 5.27 0 00-1.44-3.65zM470.92 252.346h-46.95a4.725 4.725 0 100 9.45h47.33a5.368 5.368 0 00-.38-9.45zM316.25 226.526h-59.28a4.725 4.725 0 100 9.45h59.01a5.368 5.368 0 00.27-9.45zM263.33 252.346h-6.36a4.725 4.725 0 00-1.49 9.21h4.08a5.382 5.382 0 005.38-5.38 5.314 5.314 0 00-1.61-3.83zM291.55 200.706h-34.58a4.725 4.725 0 100 9.45h33.63a5.38 5.38 0 00.95-9.45z"
      ></path>
      <path fill="#ccc" d="M782.574 464.726h-217a1 1 0 010-2h217a1 1 0 110 2z"></path>
    </svg>
  );
}
