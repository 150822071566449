/**
 * @file
 *
 * this file renders the connection card component
 */

import React, { memo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  BiArchiveIn,
  BiArchiveOut,
  BiCheck,
  BiCog,
  BiDotsVertical,
  BiRefresh,
  BiX,
} from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { BsExclamation } from 'react-icons/bs';

import { tourSteps, useTourState } from './Onboarding/Tour';
import { PERMISSION_CODES, PING_TEST, SYSTEMS } from '../constants';
import { getAssetURL } from '../service';
import { mui5Theme } from '../mui5Theme';
import { useConnectionHelper } from '../data/connectionsAndSystems';
import { navigateToSystems } from 'utils';
import { useTenantState } from 'data/user';

dayjs.extend(LocalizedFormat);

/**
 * @file
 *
 * this file renders the connection card component
 */
function EachConnection({ connection, openArchiveConfirmationDialog }) {
  const tenant = useTenantState();

  const { connectionId } = useTourState();

  const isSFSystem = connection.user_system?.system?.startsWith(SYSTEMS.SF_EC.KEY) ?? true;
  const systemLogo = getAssetURL(isSFSystem ? '/logos/SF_EC.png' : '/logos/odata.png');
  const systemName = isSFSystem ? SYSTEMS.SF_EC.LABEL : SYSTEMS.ODATA_SERVICE.LABEL;

  const isArchived = connection.is_archived;

  return (
    <Stack
      component={Card}
      variant="outlined"
      {...(connectionId &&
        connectionId === connection.connection_id && {
          'data-tour-step': tourSteps['connection-card'].id,
        })}
      position="relative"
      p={2}
      sx={(theme) => ({
        width: theme.spacing(40),
        height: theme.spacing(28),
        '&:hover [data-icon="edit"]': {
          opacity: 1,
          visibility: 'visible',
        },
        cursor: isArchived ? 'not-allowed' : 'pointer',
        backgroundColor: isArchived ? 'rgba(0,0,0,0.03)' : 'white',
      })}
    >
      <ConnectionMenu
        connection={connection}
        openArchiveConfirmationDialog={openArchiveConfirmationDialog}
        tenant={tenant}
      />
      <CardContent
        {...(!isArchived && {
          component: RouterLink,
          to: `/t/${tenant?.tenant_id}/connection/${connection.connection_id}`,
          onClick: () => tourSteps['connection-card'].next(),
        })}
        sx={{
          cursor: isArchived ? 'not-allowed' : 'pointer',
        }}
      >
        <Stack alignItems="center" spacing={1}>
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ filter: isArchived ? 'grayscale(1)' : 'none', opacity: isArchived ? 0.5 : 1 }}
          >
            <Typography color="textSecondary">{systemName}</Typography>
            <Box
              component="img"
              width={isSFSystem ? 240 : 40}
              alt={SYSTEMS[connection.user_system?.system]?.LABEL}
              src={systemLogo}
            />
          </Stack>
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{
              filter: isArchived ? 'grayscale(1)' : 'none',
              opacity: isArchived ? 0.5 : 1,
            }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              {connection.user_system.name}
            </Typography>

            <Box
              component={Typography}
              variant="body1"
              color="textSecondary"
              align="center"
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
            >
              {connection.user_system.description || '-'}
            </Box>
          </Stack>
          {connection.is_active && <ConnectivityChip connection={connection} />}
        </Stack>
      </CardContent>
    </Stack>
  );
}

const ConnectionCard = memo(EachConnection);
export default ConnectionCard;

function ConnectionMenu({ connection, openArchiveConfirmationDialog, tenant }) {
  const isArchived = connection.is_archived;
  const connectionHelper = useConnectionHelper(connection.connection_id);

  const hasManageDataflowPermission = tenant?.permissions?.some((obj) =>
    Object.values(obj).includes(PERMISSION_CODES.MANAGE_DATAFLOW)
  );

  return (
    <PopupState variant="popover" popupId={`edit-connection-${connection.connection_id}-menu`}>
      {(popupState) => {
        const { onClick, ...bindTriggerProps } = bindTrigger(popupState);
        return (
          <>
            <Tooltip title="Connection Menu">
              <IconButton
                data-icon="edit"
                size="small"
                disableRipple
                {...bindTriggerProps}
                data-tour-step={tourSteps['connection-menu'].id}
                onClick={(event) => {
                  onClick(event);
                  const { currentStepId, connectionId } = useTourState.get();

                  if (
                    currentStepId === tourSteps['connection-menu'].id &&
                    connectionId === connection.connection_id
                  ) {
                    tourSteps['connection-menu'].next();
                  }
                }}
                sx={{ position: 'absolute', right: 10, top: 10, zIndex: 1001 }}
              >
                <BiDotsVertical />
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)}>
              {!isArchived && (
                <MenuItem
                  onClick={() => {
                    popupState.close();
                    connectionHelper.refreshMetadata();
                  }}
                >
                  <ListItemIcon>
                    <BiRefresh fontSize={16} />
                  </ListItemIcon>
                  <ListItemText sx={{ fontSize: 12, ml: -1.5 }} primary="Refresh Connection" />
                </MenuItem>
              )}
              <Tooltip
                title={
                  !hasManageDataflowPermission &&
                  'You do not have permission to archive the connection. Please contact your tenant administrator.'
                }
              >
                <span>
                  <MenuItem
                    disabled={!hasManageDataflowPermission}
                    onClick={() => {
                      popupState.close();
                      const isConnectionArchived = connection.is_archived;

                      // if the connection is archived, we need to unarchive it
                      if (isConnectionArchived) {
                        connectionHelper.updateArchiveState(false);
                        return;
                      }

                      // If the connection is unarchived, we need to archive the connection and
                      // before archiving a connection, we must show a confirmation dialog
                      openArchiveConfirmationDialog(connection.connection_id);
                    }}
                  >
                    <ListItemIcon>
                      {isArchived ? <BiArchiveOut fontSize={16} /> : <BiArchiveIn fontSize={16} />}
                    </ListItemIcon>

                    <ListItemText
                      sx={{ fontSize: 12, ml: -1.5 }}
                      primary={isArchived ? 'Unarchive Connection' : 'Archive Connection'}
                    />
                  </MenuItem>
                </span>
              </Tooltip>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  navigateToSystems(connection.tenant_id, connection.user_system_id);
                }}
              >
                <ListItemIcon>
                  <BiCog fontSize={16} />
                </ListItemIcon>
                <ListItemText sx={{ fontSize: 12, ml: -1.5 }} primary="Navigate to System" />
              </MenuItem>
            </Menu>
          </>
        );
      }}
    </PopupState>
  );
}

function ConnectivityChip({ connection }) {
  const [activeChip, setActiveChip] = useState();

  const pingState = connection.user_system.ping_status;

  const { bgcolor, color } = chipColors[pingState] || chipColors.default;

  if (connection.is_archived) {
    return (
      <Stack width="100%" alignItems="flex-start" position="absolute" bottom={15} left={15}>
        <Chip
          size="small"
          variant="outlined"
          label="Archived"
          color="warning"
          sx={{
            borderRadius: 1,
            fontSize: 12,
            cursor: connection.is_archived ? 'not-allowed' : 'none',
          }}
        />
      </Stack>
    );
  }

  return (
    <Stack
      position="absolute"
      alignItems="center"
      left={15}
      bottom={12}
      direction="row"
      sx={(theme) => ({ minWidth: theme.spacing(24) })}
      spacing={1}
    >
      <motion.div
        onHoverStart={() => setActiveChip(connection.connection_id)}
        onHoverEnd={() => setActiveChip()}
        style={{ zIndex: 1001 }}
      >
        <Stack {...CHIP_STYLES} bgcolor={bgcolor} color={color}>
          {iconComponents[pingState] || iconComponents.default}
        </Stack>
      </motion.div>
      <Stack>
        <motion.div
          animate={activeChip === connection.connection_id ? 'open' : 'closed'}
          initial="closed"
          transition={{ ease: 'easeOut', duration: 0.2 }}
          variants={pingTestAnimation}
        >
          <Stack
            position="relative"
            bottom={0}
            sx={(theme) => ({
              borderRadius: theme.spacing(2),
              zIndex: 1000,
            })}
          >
            <Typography variant="body2" fontWeight="500" color={bgcolor}>
              {pingState === PING_TEST.SUCCESS ? 'Connected' : 'Not Connected'}
            </Typography>
            <Typography mt={-0.5} variant="caption" color="GrayText">
              {connection.user_system.last_pinged_at
                ? `last verified on ${dayjs(connection.user_system.last_pinged_at).format('lll')}`
                : 'connection not verified'}
            </Typography>
          </Stack>
        </motion.div>
      </Stack>
    </Stack>
  );
}

const CHIP_SIZE = 25;
const CHIP_STYLES = {
  width: CHIP_SIZE,
  height: CHIP_SIZE,
  borderRadius: 10,
  alignItems: 'center',
  justifyContent: 'center',
};

const iconComponents = {
  [PING_TEST.SUCCESS]: <BiCheck fontSize={24} style={{ margin: 4 }} />,
  [PING_TEST.FAILURE]: <BiX fontSize={24} style={{ margin: 4 }} />,
  default: <BsExclamation fontSize={24} style={{ margin: 2 }} />,
};

const chipColors = {
  [PING_TEST.SUCCESS]: {
    bgcolor: mui5Theme.palette.secondary.main,
    color: mui5Theme.palette.secondary.contrastText,
  },
  [PING_TEST.FAILURE]: {
    bgcolor: mui5Theme.palette.error.main,
    color: mui5Theme.palette.error.contrastText,
  },
  default: {
    bgcolor: mui5Theme.palette.grey[500],
    color: mui5Theme.palette.grey[100],
  },
};
const pingTestAnimation = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '-20%' },
};
